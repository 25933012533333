<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="black" width="48px" height="48px"><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><g><g><path d="M11,5v11.17l-4.88-4.88c-0.39-0.39-1.03-0.39-1.42,0l0,0c-0.39,0.39-0.39,1.02,0,1.41l6.59,6.59 c0.39,0.39,1.02,0.39,1.41,0l6.59-6.59c0.39-0.39,0.39-1.02,0-1.41l0,0c-0.39-0.39-1.02-0.39-1.41,0L13,16.17V5 c0-0.55-0.45-1-1-1l0,0C11.45,4,11,4.45,11,5z"/></g></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconArrowDown',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
